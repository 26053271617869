// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import jsPDF from 'jspdf'
import store from '@/store'
import 'jspdf-autotable'
import logo from '@/assets/images/logo/logo-black.png'
import { boldFont } from '@/assets/fonts/ar/SSTArabic-Bold'
import { regularFont } from '@/assets/fonts/ar/SSTArabic-Light'

export default function useSubscriptionsInvoice() {
  // Use toast
  const toast = useToast()

  const iFrameSrc = ref(null)

  const subscriptionsInvoice = ref(null)

  const exportSubscriptionsInvoiceTotals = ref(null)

  const footerHeaderAR = 'اتفاقية التوريد والتركيب'

  const footerText1AR = 'مقدمة:'
    + '\n'
    + 'تقوم بتوفير نظام نقاط البيع وادارة المتاجر الخاص بها تحت مسمى "الطرف الأول" - شركة النطاق الطازج التجارية –"“FRESH” "النظام "او'
    + '\n'
    + 'الطرف الثاني" - مستخدم النظام"'

    + '\n'
    + '\n'
    + '\n'
    + 'شروط الدفع:'
    + '\n'
    + 'اتفق الطرفان على شروط الدفع للحالتين اما الدفع المقدم او نظام الاقساط'
    + '\n'
    + 'في حالة الدفع المقدم التزم الطرف الثاني بدفع كامل المبلغ - ويكون الدفع مقدمًا عند الطلب.'
    + '\n'
    + '\n'
    + 'في حالة الدفع عن طريق نظام التقسيط تطبق الشروط التالية على أي معاملات تتم باستخدام التقسيط كخيار دفع'
    + '\n'
    + '\n'
    + 'على بعض المشتريات وفي حالات معينه )"الأقساط"(  تتيح فريش طريقة الدفع بالتقسيط'
    + '\n'
    + '\n'
    + 'عن طريق الشركة )هلا( التي تتعامل معها بنظام المدفوعات الخاصة بك سيكون الدفع بالتقسيط عن طريق الشركة.'
    + '\n'
    + '\n'
    + 'إذا رفضت الشركة الذي تتعامل معها ، فلن يتم تحويل طلبك إلى خطة سداد بالتقسيط و يجب الدفع مقدما عند تقديم الطلب لاتمام الخدمة'
    + '\n'
    + '\n'
    + '\n'
    + 'التوريد والتركيب:'
    + '\n'
    + 'من تاريخ استلام الدفعه كاملة من الطرف الثاني سيتم ارسال مواعيد التوريد والتركيب من الطرف الاول خلال مده اقصاها ١٤ يوم عمل'
    + '\n'
    + 'جميع المبالغ المدفوعة غير مستردة **'
    + '\n'
    + '\n'
    + '\n'
    + 'الضمان:'
    + '\n'
    + 'الضمان يشمل جميع الانظمة الموضحه في عرض السعر اعلاه خلال فترة التعاقد المقرونة برسوم رخصة الاستخدام  او الدعم الفني السنوية للنظام.'
    + '\n'
    + 'عرض السعر يتضمن )الدعم التقني , تحديث النظام( هذه الخدمات يتم تفعيلها من تاريخ اصدار رخصة الاستخدام الخاصه بالنظام الموضحه اعلاه. '
    + '\n'
    + 'هذا الضمان لمدة عام واحد ويتم تجديده تلقائيا مع تجديد رخصة النظام او الدعم الفني الخاصه بمتجر لطرف الثاني اعلاه.'
    + '\n'
    + '\n'
    + 'يغطي نظام ضمان الشركة المصنعة للأجهزة وفقًا للشروط والأحكام الخاصة بها وفي حالة وجود عيب او خلل مصنعي يجب مراجعة وكيل الاجهزة STC'
    + '\n'
    + '* في السعودية شركة الاتصالات السعودية'
    + '\n'
    + '\n'
    + 'الموقع وارقام التواصل'
    + '\n'
    + '\n'

    + 'جدة:'
    + '\n'

    + 'STC Device Service Center'
    + '\n'

    + 'Prince Sultan Rd, An Nahdah, Jeddah 23615'
    + '\n'

    + '9200 26900'
    + '\n'
    + '\n'

    + 'الرياض:'
    + '\n'

    + 'STC Device Service Center'
    + '\n'

    + 'King Abdul Aziz Branch Rd, Al Ghadir, Riyadh 11564'
    + '\n'

    + '9200 26900'
    + '\n'
    + '\n'
    + '\n'

    + 'التركيب والتدريب:'
    + '\n'

    + 'يتعهد الطرف الاول بتقديم التدريب اللازم على النظام والاجهزة للطرف الثاني )بحد اقصى ثلاثة اشخاص مجتمعين لكل متجر( خلال السنة الاولى من فترة التعاقد للمتجر.'
    + '\n'

    + 'يتعهد الطرف الثاني توفير احتياجات التدريب الاساسية داخل المتجر ,مساحه للتدريب, اوقات مخصصه للتدريب, انترنت, الكهرباء'
    + '\n'
    + '\n'
    + '\n'

    + 'الالتزامات:'
    + '\n'

    + 'لا يتحمل الطرف الأول بأي حال من الأحوال مسؤولية حدوث أي أعراض مباشرة أو غير مباشرة، على سبيل المثال لا الحصر ، خسارة الأرباح وفقدان المدخرات والأضرار الناشئة عن سوء الاستخدام واداء المنتجات )الأجهزة و / أو البرامج( ، أو الأعمال التجارية أو الخسائر الاقتصادية الناشئة عن أو فيما يتعلق بهذه الاتفاقية ، أو غيرها من المواد التي يوفرها الطرف الأول.'
    + '\n'
    + '\n'
    + '\n'

    + 'Bank Name:مصرف الانماء'
    + '\n'

    + 'Account Title: شركة النطاق الطازج التجارية'
    + '\n'

    + 'Account No. 68203403309000'
    + '\n'

    + 'IBAN: SA6105000068203403309000'
    + '\n'

  const formatData = () => {
    exportSubscriptionsInvoiceTotals.value = [
      {
        key: 'Total Taxable Amount (Excluding VAT)\n(الإجمالي الخاضع للضریبة)غیر شامل ضریبة القیمة المضافة',
        amount: `${subscriptionsInvoice.value.total_without_vat.toFixed(2)} SAR`,
      },
      {
        key: 'Total Vat\nمجموع ضریبة القیمة المضافة',
        amount: `${subscriptionsInvoice.value.total_vat.toFixed(2)} SAR`,
      },
      {
        key: 'Total\nإجمالي',
        amount: `${subscriptionsInvoice.value.total.toFixed(2)} SAR`,
      },
    ]
  }

  const exportPDF = () => {
    formatData()

    const doc = new jsPDF('p', 'px', 'a4')

    // Add fonts
    doc.addFileToVFS('SSTArabic-Light.ttf', regularFont)
    doc.addFont('SSTArabic-Light.ttf', 'SSTArabic', 'regular')

    doc.addFileToVFS('SSTArabic-Bold.ttf', boldFont)
    doc.addFont('SSTArabic-Bold.ttf', 'SSTArabic', 'bold')

    doc.setFontSize(10)
    doc.setFont('helvetica', 'bold')

    // Center
    doc.addImage(logo, 'png', 185, 30, 70, 50)
    doc.setFont('SSTArabic', 'bold') // set font
    doc.setFontSize(15)
    doc.text('الفاتورة الضريبية المبدئية', 220, 100, null, null, 'center')
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12)
    doc.text('Proforma Tax Invoice', 220, 115, null, null, 'center')

    doc.setFontSize(10)

    // Invoice Number
    doc.autoTable({
      body: [{ en: 'Invoice#', value: subscriptionsInvoice.value.invoice_id, ar: 'رقم الفاتورة' }],
      theme: 'striped',
      margin: { top: 135, bottom: 50 },
      tableWidth: doc.internal.pageSize.getWidth() / 1.8,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { ar: { halign: 'right' }, value: { halign: 'center' } },
    })

    doc.addImage(subscriptionsInvoice.value.qr_image, doc.internal.pageSize.getWidth() - 120, doc.lastAutoTable.finalY / 1.25, 100, 100)

    // Invoice Issue Date
    doc.autoTable({
      body: [
        { en: 'Invoice Issue Date', value: subscriptionsInvoice.value.issue_date, ar: 'تاریخ إصدار الفاتورة' },
        { en: 'P.O.#', value: `SO-${subscriptionsInvoice.value.invoice_id}`, ar: 'رقم طلب الشراء' },
      ],
      theme: 'striped',
      margin: { top: 100, bottom: 50 },
      tableWidth: doc.internal.pageSize.getWidth() / 1.8,
      startY: doc.lastAutoTable.finalY + 20,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { ar: { halign: 'right' }, value: { halign: 'center' } },
    })

    const lastTable = doc.lastAutoTable.finalY + 20

    // Seller
    doc.autoTable({
      body: [
        { en: 'Seller', value: '', ar: 'تاجر' },
        { en: 'Name', value: subscriptionsInvoice.value.seller.name, ar: 'اسم' },
        { en: 'Address Line 1', value: subscriptionsInvoice.value.seller.address, ar: '1 عنوان' },
        { en: 'Address Line 2', value: subscriptionsInvoice.value.seller.address_2, ar: '2 عنوان' },
        { en: 'City', value: subscriptionsInvoice.value.seller.city, ar: 'مدينة' },
        { en: 'Country', value: subscriptionsInvoice.value.seller.country, ar: 'دولة' },
        { en: 'ZIP/Postal Code', value: subscriptionsInvoice.value.seller.postal_code, ar: 'الرمز/ الكود البريدي' },
        { en: 'Phone', value: subscriptionsInvoice.value.seller.phone, ar: 'هاتف' },
        { en: 'TRN', value: subscriptionsInvoice.value.seller.TRN, ar: 'TRN' },
        { en: 'Other Seller ID', value: `Company ID: ${subscriptionsInvoice.value.seller.cr_number}`, ar: 'معرف الآخر' },
      ],
      theme: 'striped',
      margin: { top: 100, bottom: 50, right: 230 },
      tableWidth: doc.internal.pageSize.getWidth() / 2.32,
      startY: lastTable,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { ar: { halign: 'right' }, value: { halign: 'center' } },
    })

    // Buyer
    doc.autoTable({
      body: [
        { en: 'Buyer', value: '', ar: 'مشتر' },
        { en: 'Name', value: subscriptionsInvoice.value.entity.business_name, ar: 'اسم' },
        { en: 'Address Line 1', value: subscriptionsInvoice.value.entity.address, ar: '1 عنوان' },
        { en: 'Address Line 2', value: subscriptionsInvoice.value.entity.address_2, ar: '2 عنوان' },
        { en: 'City', value: subscriptionsInvoice.value.entity.city, ar: 'مدينة' },
        { en: 'Country', value: subscriptionsInvoice.value.entity.country, ar: 'دولة' },
        { en: 'ZIP/Postal Code', value: subscriptionsInvoice.value.entity.postal_code, ar: 'الرمز/الكود البريدي' },
        { en: 'Phone', value: subscriptionsInvoice.value.entity.owner_contact, ar: 'هاتف' },
        { en: 'TRN', value: subscriptionsInvoice.value.entity.tax_id, ar: 'TRN' },
        { en: 'Other Buyer ID', value: `Company ID: ${subscriptionsInvoice.value.entity.cr_number}`, ar: 'معرف الآخر' },
      ],
      theme: 'striped',
      margin: { top: 100, bottom: 50, left: 225 },
      tableWidth: doc.internal.pageSize.getWidth() / 2.32,
      startY: lastTable,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { ar: { halign: 'right' }, value: { halign: 'center' } },
    })

    doc.setFont('SSTArabic', 'bold')
    doc.text('Line Items', 30, doc.lastAutoTable.finalY + 50)
    doc.autoTable({
      body: subscriptionsInvoice.value.items,
      columns: [
        { header: 'Nature Of Service\nتفاصیل الخدمة', dataKey: 'name' },
        { header: 'Unit Price\nسعر الوحدة', dataKey: 'price' },
        { header: 'Quantity\nالكمیة', dataKey: 'quantity' },
        { header: 'Item Subtotal (Including VAT)\nالمجموع\n(شامل ضریبة القیمة المضافة)', dataKey: 'subtotal' },
      ],
      theme: 'striped',
      startY: doc.lastAutoTable.finalY + 55,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { price: { halign: 'right' }, quantity: { halign: 'center' }, subtotal: { halign: 'right' } },
    })

    // doc.text('Totals', 30, doc.lastAutoTable.finalY + 25)
    doc.autoTable({
      head: [[{
        content: 'Totals',
        colSpan: 2,
        styles: { fillColor: [204, 204, 204] },
      }]],
      body: exportSubscriptionsInvoiceTotals.value,
      columns: [
        { header: 'key', dataKey: 'key' },
        { header: 'amount', dataKey: 'amount' },
      ],
      theme: 'striped',
      startY: doc.lastAutoTable.finalY + 20,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { key: { fontStyle: 'bold' }, amount: { halign: 'right' } },
    })

    doc.setFont('SSTArabic', 'bold')
    doc.text('Tax Summary', 30, doc.lastAutoTable.finalY + 20)
    // Tax Summary
    doc.autoTable({
      body: [
        { en: 'Tax Details', value: 'Taxable Amount (SAR)', ar: 'Tax Amount' },
        { en: 'Standard Rate (15%)', value: `${subscriptionsInvoice.value.total_without_vat.toFixed(2)} SAR`, ar: `${subscriptionsInvoice.value.total_vat.toFixed(2)} SAR` },
        { en: 'Total', value: `${subscriptionsInvoice.value.total_without_vat.toFixed(2)} SAR`, ar: `${subscriptionsInvoice.value.total_vat.toFixed(2)} SAR` },
      ],
      theme: 'striped',
      startY: doc.lastAutoTable.finalY + 25,
      headStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fillColor: [204, 204, 204],
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      bodyStyles: {
        font: 'SSTArabic',
        fontSize: 9,
        fontStyle: 'Bold',
        textColor: [0, 0, 0],
        lineColor: [108, 108, 108],
        lineWidth: 0.1,
      },
      columnStyles: { value: { halign: 'right' }, ar: { halign: 'right' } },
    })

    doc.addPage()

    // Footer Text Arabic
    doc.setFont('SSTArabic', 'bold')
    doc.text(footerHeaderAR, doc.internal.pageSize.getWidth() - 30, 30, null, null, 'right')

    const splitfooterText1AR = doc
      .setFontSize(8)
      .splitTextToSize(footerText1AR, doc.internal.pageSize.getWidth())

    doc
      .setFont('SSTArabic', 'regular')
      .text(splitfooterText1AR, doc.internal.pageSize.getWidth() - 30, 40, null, null, 'right')

    // Footer ON each Page
    const pageCount = doc.internal.getNumberOfPages()

    // For each page, print the page number and the total pages
    for (let i = 1; i <= pageCount; i++) {
      doc.setDrawColor(0, 0, 0)
      doc.line(30, doc.internal.pageSize.getHeight() - 20, doc.internal.pageSize.getWidth() - 30, doc.internal.pageSize.getHeight() - 20)
      // Go to page i
      doc.setPage(i)
      doc.text(`Page ${String(i)} of ${String(pageCount)}`, doc.internal.pageSize.getWidth() - 30, doc.internal.pageSize.getHeight() - 10, null, null, 'right')
    }
    // doc.output('dataurlnewwindow', { filename: 'Subscriptions Invoice.pdf' })

    iFrameSrc.value = doc.output('datauristring')
  }

  async function fetchSubscriptionsInvoice(id) {
    try {
      const response = await store.dispatch('app-subscription/fetchSubscriptionsInvoice', id)
      // eslint-disable-next-line prefer-destructuring
      subscriptionsInvoice.value = response?.data?.data[0]
      subscriptionsInvoice.value.items = response?.data?.data[0].items.map(obj =>
      {
        const temp = { ...obj }
        if (obj.start_date && obj.end_date) {
          temp.name = `${obj.name}\n Subscription Date: ${(obj.start_date)} \n Expiry Date: ${obj.end_date}`
        }
        return temp
      })
      exportPDF()
    } catch (error) {
      console.error(error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Subscriptions Invoice',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    fetchSubscriptionsInvoice,

    iFrameSrc,
  }
}
