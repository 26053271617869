<template>
  <iframe
    :src="iFrameSrc"
    :width="windowWidth"
    :height="windowHeight"
    frameborder="0"
  />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import subscriptionStoreModule from '../subscriptionStoreModule'
import useSubscriptionsInvoice from './useSubscriptionsInvoice'

export default {
  setup() {
    const SUBSCRIPTION_STORE_MODULE_NAME = 'app-subscription'

    // Register module
    if (!store.hasModule(SUBSCRIPTION_STORE_MODULE_NAME)) store.registerModule(SUBSCRIPTION_STORE_MODULE_NAME, subscriptionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUBSCRIPTION_STORE_MODULE_NAME)) store.unregisterModule(SUBSCRIPTION_STORE_MODULE_NAME)
    })

    const {
      fetchSubscriptionsInvoice,
      iFrameSrc,
    } = useSubscriptionsInvoice()

    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    fetchSubscriptionsInvoice(router.currentRoute.params.id)

    return {
      iFrameSrc,

      windowWidth,
      windowHeight,
    }
  },
}
</script>
